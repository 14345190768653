<template>
  <div class="approvalOfTradeAgreement">
    <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approveRequest"
        @reject="rejectRequest"
        @cardClick="detailAgreement"
        @filterR="filterBySupplierName"
        @filterB="filterByBranchCode"
        filterData
      />
    </q-pull-to-refresh>
    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Acordo Comercial</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";
import formatString from "../../helpers/formatString";
import removeCharSpecial from "../../helpers/removerCharSpecial";

export default {
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "agreement",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "amountOfAgreement",
          label: "",
          type: "currency",
          class: "text-overline"
        },
        {
          field: "agreementDate",
          label: "",
          type: "string",
          class: "text-overline"
        }
      ],
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        { label: "Rejeitar", color: "red", icon: "block", eventEmit: "reject" }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB,
      modalReason: false,
      descriptionRejection: null,
      agreementSelected: null,
      dataForRejected: {}
    };
  },
  computed: {
    ...mapState("tradeAgreement", ["agreement"]),

    allFilters() {
      let agreement = this.agreement;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          agreement.sort((a, b) => a.amountOfAgreement - b.amountOfAgreement);
          break;
        case "sLgSm":
          agreement.sort((a, b) => b.amountOfAgreement - a.amountOfAgreement);
          break;
        case "sNewOld":
          agreement.sort((a, b) => b.agreementDate - a.agreementDate);
          break;
        case "sOldNew":
          agreement.sort((a, b) => a.agreementDate - b.agreementDate);
          break;
      }

      if (this.filterR) {
        agreement = agreement.filter(agr => {
          return agr.agreementNumber.trim().indexOf(this.filterR.trim()) >= 0;
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          agreement = agreement.filter(agr => {
            return (
              agr.branchCode.trim().indexOf(sessionStorage.filterWebB.trim()) >=
              0
            );
          });
        } else {
          agreement = agreement.filter(agr => {
            return agr.branchCode.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return agreement.map(agr => {
        return {
          id: agr.id,
          agreement: `${agr.agreementNumber} - ${agr.custumerFancy}`,
          branch: `${agr.branchCode} - ${agr.branchName}`,
          // custumerFancy: agr.custumerFancy,
          amountOfAgreement: agr.amountOfAgreement,
          agreementDate: formatString(agr.agreementDate, "date"),
          agreementNumber: agr.agreementNumber
        };
      });
    }
  },
  methods: {
    ...mapActions("tradeAgreement", [
      "getAgreement",
      "approvesRejectsTradeAgreement"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    approveRequest(request) {
      this.sendResponse(request, "approved", "APROVAR", "text-green");
    },
    rejectRequest(request) {
      this.agreementSelected = request;
      this.sendResponse(request, "rejected", "REJEITAR", "text-red");
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Acordo ${request.agreementNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          if (response === "rejected") {
            this.dataForRejected = {
              request: request,
              response: "rejected"
            };
            this.modalReason = true;
            return;
          }
          try {
            const reasonReject = "approved";
            await this.approvesRejectsTradeAgreement({
              request,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.getAgreement();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    async confirmReject() {
      if (String(this.descriptionRejection).trim().length < 8) {
        this.$q.dialog({
          title: "Cicopal Aprova",
          message: "Favor detalhar o motivo da Rejeição deste acordo!"
        });

        return;
      }

      try {
        const { request, response } = this.dataForRejected;
        const reasonReject = removeCharSpecial(this.descriptionRejection);
        await this.approvesRejectsTradeAgreement({
          request,
          response,
          reasonReject
        });
        this.setNotification({
          message: "Resposta enviada com sucesso.",
          color: "green",
          position: "top"
        });
        this.getAgreement();
      } catch (error) {
        this.setNotification({
          message: error.message,
          color: "red",
          position: "top"
        });
      }
      this.modalReason = false;
    },
    detailAgreement(agreementId) {
      this.$router.push({
        name: "agreementDetail",
        params: { agreementId }
      });
    },
    filterBySupplierName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getAgreement();
      done();
    }
  },
  created() {
    this.getAgreement();
  }
};
</script>

<style lang="scss">
.approvalOfTradeAgreement {
  width: 100%;
}
</style>
